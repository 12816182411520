export const CHANNEL_ID = ':channelId'
export const commonProperties = {
  //authentication
  login: 'authentication',
  register: 'register',
  resetPassword: 'password/reset',
  roles: 'roles',

  //users
  currentUser: 'users/current',

  //organisations
  organisations: 'organisations',
  info: 'organisations/info',

  //messaging
  sms_list: CHANNEL_ID + '/sms',
  otpMobileVerification: CHANNEL_ID + '/sms/coupon',
  otpMobileValidation: CHANNEL_ID + '/sms/coupon/validation',
  sms_send: CHANNEL_ID + '/sms/send',
  voice_send: CHANNEL_ID+'/voice/send',
  sms_list_search: CHANNEL_ID + '/sms/search',
  sms_list_export_reports: CHANNEL_ID + '/reports/export',
  sms_list_export: CHANNEL_ID + '/sms/export',
  response_list: CHANNEL_ID + '/sms/responses',
  response_list_search: CHANNEL_ID + '/sms/responses/search',
  scheduled_list: CHANNEL_ID + '/sms/scheduled',
  scheduled_list_search: CHANNEL_ID + '/sms/scheduled/search',
  whatsapp_list: CHANNEL_ID + '/whatsapp',
  sender_id_list: CHANNEL_ID + '/senders',


  //template
  template_list: CHANNEL_ID + '/models',
  template: CHANNEL_ID + '/models/:templateId',
  template_list_search: CHANNEL_ID + '/models/search',

  //address book
  contact_list: 'contacts',
  contact: 'contacts/:contactId',
  address_book: 'contacts/book',
  contact_group_assignation: 'contacts/groups',
  contact_export: 'contacts/export',
  blacklist_list: 'contacts/blacklist',
  blacklist_export: 'contacts/export/blacklist',
  invalid_contact_list: 'contacts/invalid',
  invalid_contact_list_export: 'contacts/export/invalid',
  group_list: 'groups',
  group: 'groups/:groupId',
  group_list_search: 'groups/search',
  group_contact_list: 'groups/:groupId/contacts',


  //channels
  channels: 'channels',
  channels_allowed: 'channels/allowed',
  channels_id: 'channels/:channelId',

  //networks
  networks: 'networks',



  //billing
  invoices_list: 'invoices',
  invoices_list_search: 'invoices/search',
  invoice_download: 'invoices/:invoiceId/pdf?base64=false',
  pricing_list: 'pricing',
  transaction: 'transaction',

  //settings
  api_keys_list: CHANNEL_ID + '/keys',
  api_keys_disable: CHANNEL_ID + '/keys/:keyId/disable',
  api_keys_enable: CHANNEL_ID + '/keys/:keyId/enable',
  alert_settings: CHANNEL_ID + '/settings',
  webhooks_settings: CHANNEL_ID + '/webhooks',

  //credits
  transfers_list: 'transfers',
  transfers_list_search: 'transfers/search',

  //suborganisations
  suborganisations_list: 'suborganisations',

  //consumption
  consumption_day: CHANNEL_ID + '/consumption/day',
  consumption_month: CHANNEL_ID + '/consumption/month',
  consumption_days: CHANNEL_ID + '/consumption/days',
  consumption: CHANNEL_ID + '/consumption',

  //SMTP config
  smtp_config: CHANNEL_ID+'/configuration/email',

  //google calendar
  calendar_google_accounts_list: CHANNEL_ID + '/calendars/users',
  calendar_templates_list: CHANNEL_ID + '/calendars/messages',
  calendar_template: CHANNEL_ID + '/calendars/messages/:templateId',
  calendar_push_google_code: CHANNEL_ID + '/calendars/token',
  calendar_calendars_list: CHANNEL_ID + '/calendars/users/:credentialId'

}
