import {commonProperties} from './environment.common';

// export const BASE_URL = 'https://rest.dev.smsmode.fr/';
export const BASE_URL = 'https://ui.smsmode.fr/rest/';
export const STATS_URL = 'https://ui.smsmode.fr/commons/v1/stats';
const GG_SCOPE_PROD=  'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar.readonly';
export const GG_CLIENT_ID_PROD= '776770398236-9vupl4jhbf6lpva9m7772kms93ffd4ou.apps.googleusercontent.com';

export const GG_SCOPE=  'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';
export const GG_CLIENT_ID=  '143625870396-f0imejp6rsc4i8l19ndr04tloh0hhm99.apps.googleusercontent.com';

//messages
export const MAX_LENGTH = 765;
export const MAX_LENGTH_UNICODE = 315;

export const environment = {
  ...commonProperties,
  production: false,
};
